<template>
  <div style="padding:20px; zoom:90%;">
    <v-row>
      <v-col cols="12">
        <v-text-field
          :label="'Fecha de vencimiento'"
          v-model="Info.Date"
          outlined
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          :label="'Importe a pagar - solo numeros y punto decimal '"
          @paste="onPaste"
          @keypress="isNumber($event)"
          v-model="Info.Import"
          outlined
        />
      </v-col>
      <v-col cols="12">
        <v-text-field
          name="Referencia"
          :label="'Referencia original'"
          type="text"
          outlined
          v-model="Info.RefOri"
        />
      </v-col>
      <v-col cols="12">
        <v-btn
          color="success"
          min-width="148"
          @click="CrearLineaRef"
          class="my_font"
        >
          Verificar Referencia
        </v-btn>
      </v-col>
      <v-col cols="12" style="padding-top: 0;">
        <table v-if="LineaRef">
            <tr>
                <td>
                    Fecha Condensa 
                </td>
                <td>
                   {{ DC }}
                </td>
            </tr>
            <tr>
                <td>
                    Importe Condensado &nbsp;&nbsp;&nbsp;&nbsp;
                </td>
                <td>
                   {{ IC }}
                </td>
            </tr>
            <tr>
                <td>
                    Referencia 
                </td>
                <td>
                   {{ LineaRef }}
                </td>
            </tr>
             <tr>
                <td>
                    Con Prefijo 
                </td>
                <td>
                <strong>   {{ LineaRef2 }} </strong>
                </td>
            </tr>
            <tr>
                <td>
                    <div :style="{'color':mycolor}">
                        <h4>Valido</h4>
                    </div>
                </td>
                <td>
                    <div :style="{'color':mycolor}">
                        <h4>{{ Valido }}</h4>
                    </div>
                </td>
            </tr>
        </table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      LineaRef: "",
      Valido: "",
      DC:'',
      IC:'',
      mycolor: "black",
      menu2: false,
      Info: {
        RefOri: "",
        Date: "",
        Import: "",
      },
      Info2: {
        RefOri: "102010130610030311708563232",
        Date: "2015-04-20",
        Import: "207.00",
      },
    };
  },
  mounted() {
    setTimeout(() => {
      $(".v-text-field__details").css({"display":"none"})
    }, 10);
  },
  methods: {
    CrearLineaRef() {
      this.Clear();
      this.GeneratorLineRef(this.Info);
    },
    onPaste(evt) {
      if (!isNaN(evt.clipboardData.getData("text")) == false) {
        setTimeout(() => {
          this.Info.Import = "";
        }, 10);
      }
    },
    async GeneratorLineRef(Info) {
      var constante = 2;
      var residuo = 97;
      var prefijo = 201;
      //Fecha --------------------------------------------------
      var CodeF = await this.getCodeDate(Info.Date);

      var CodeI = await this.getCodeImport(Info.Import);

      var CodeR = await this.getCodeRef(Info.RefOri, constante, residuo);

      var LineRef =
        CodeR["folioRef"] + CodeF + CodeI + constante + CodeR["Refclave"];

      console.log(Info.RefOri);
      if (LineRef == Info.RefOri) {
        this.Valido = "SI";
        this.mycolor = "green"
      } else {
        this.Valido = "NO";
        this.mycolor = "red"
      }

      this.DC = CodeF
      this.IC = CodeI
      this.LineaRef = Info.RefOri;
      this.LineaRef2 = LineRef;
      //this.LineaRef2 = prefijo + LineRef;
    },

    async getCodeDate(FechaX) {
      var vx = this.$root;
      return new Promise(function(resolve, reject) {
        var CodeY, CodeM, CodeD;
        var CodeF = "";
        var date = FechaX.split("-");

        if (date.length == 1) {
          date = FechaX.split("/");
        }

        if (!date[2]) {
          vx.swalAlert("warning", "Formato fecha incorrecto", null);
          return false;
        }

        if (date[2].length == 4) {
          var year = date[2];
          date[2] = date[0];
          date[0] = year;
        }

        for (var x = 0; x < date.length; x++) {
          date[x] = parseInt(date[x]);
        }

        CodeY = (date[0] - 2013) * 372;
        CodeM = (date[1] - 1) * 31;
        CodeD = date[2] - 1;

        // solo enteros
        var CodeF2 = "" + parseInt(CodeY + CodeM + CodeD);

        for (var x = 0; x < 4; x++) {
          if (CodeF2[x]) {
            CodeF = CodeF + CodeF2[x];
          } else {
            CodeF = "0" + CodeF;
          }
        }

        resolve(CodeF);
      });
    },

    async getCodeImport(importX) {
      var vx = this.$root;
      return new Promise(function(resolve, reject) {
        var CodeF2, CodeF, CodeI, Ponde;
        var CodeI = (Ponde = CodeP = "");
        var impot = importX;
        var Zuma = 0;
        var con = 0;

        if (impot == "") {
          vx.swalAlert("warning", "Vacio el importe a pagar", null);
          return false;
        }

        impot = impot.replaceAll(",", "");

        var verifi = impot.split(".");

        if (verifi.length == 1) {
          impot = impot + ".00";
        } else {
          impot = verifi[0] + "." + verifi[1];
        }

        if (impot.length < 12) {
          var vlx = 0;
          Ponde = "71371371.37";
          for (var x = 0; x < 11; x++) {
            if (impot[x]) {
              CodeI = CodeI + impot[x];
            } else {
              CodeI = "0" + CodeI;
            }
          }
        } else {
          CodeI = impot;
          for (var x = 0; x < impot.length; x++) {
            let val = impot[x];

            if (val == ".") {
              Ponde = Ponde + ".";
            } else {
              switch (con) {
                case 0:
                  Ponde = Ponde + "7";
                  //Zuma = Zuma + (parseInt(val) * 7)
                  con = 1;
                  break;
                case 1:
                  Ponde = Ponde + "1";
                  //Zuma = Zuma + (parseInt(val) * 1)
                  con = 2;
                  break;
                case 2:
                  Ponde = Ponde + "3";
                  //Zuma = Zuma + (parseInt(val) * 3)
                  con = 0;
                  break;
              }
            }
          }
        }

        for (var x = 0; x < Ponde.length; x++) {
          if (CodeI[x] != ".") {
            Zuma = Zuma + parseInt(CodeI[x]) * parseInt(Ponde[x]);
          }
        }

        var i = 0;
        var ii = false;

        do {
          let y = Zuma % 10;
          if (y == i) {
            ii = true;
          } else {
            i = y;
          }
        } while (ii == false);
        var CodeP = "" + i;
        resolve(CodeP);
      });
    },

    async getCodeRef(RefOriX, constante, residuo) {
      var vx = this.$root;
      return new Promise(function(resolve, reject) {
        var constant = 2;
        //limite 24 caracteres + valor constante 2

        if (RefOriX == "") {
          vx.swalAlert("warning", "Vacio la referencia original", null);
          return false;
        }

        if (RefOriX.length < 24) {
          vx.swalAlert(
            "warning",
            "La referencia original tiene menos de 24",
            null
          );
          return false;
        }

        var RefOri = RefOriX.substring(0, 24) + constant;
        var ref = [];
        var ponde = [];
        var con = 11;
        var Zuma = 0;

        for (var x = 0; x < RefOri.length; x++) {
          ponde.push(con);
          con = con + constant;
          constant = constant + constante;

          if (con >= 25) {
            con = 11;
            constant = constante;
          }
          if (constant == 6) {
            constant = constante;
          }
        }
        var ponde = ponde.reverse();

        for (var x = 0; x < ponde.length; x++) {
          Zuma = Zuma + parseInt(RefOri[x]) * parseInt(ponde[x]);
        }

        let y = (Zuma % residuo) + 1;

        if (y < 10) {
          y = "0" + parseInt(y);
        } else {
          y = "" + parseInt(y);
        }
        
        var data = { Refclave: y, folioRef: RefOriX.substring(0, 19) };
        resolve(data);
      });
    },

    Clear() {
      this.Valido = "";
      this.LineaRef = "";
      this.mycolor = "black"
    },

    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      //if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    fixinput(value) {
      if (value.cantidad == "") {
        value.cantidad = 0;
      }
    },
  },
};
</script>

<style scoped>
.MyInputNumber {
  font-size: 18px;
  background: white;
  padding: 3px;
  border: 1px solid black;
  width: 100%;
}
.v-text-field__details{
  display: none !important;
}
</style>
